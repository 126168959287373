import { Injectable } from "@angular/core";

@Injectable()
export class SharedDataService {
  private dataStore: { [key: string]: any } = {};

  setData(key: string, value: any): void {
    this.dataStore[key] = value;
  }

  getData(key: string): any {
    return this.dataStore[key];
  }

  clearData(key: string): void {
    delete this.dataStore[key];
  }

  // Optionally, you can add methods to clear all data or check if a key exists
  clearAllData(): void {
    this.dataStore = {};
  }

  keyExists(key: string): boolean {
    return this.dataStore.hasOwnProperty(key);
  }
}
