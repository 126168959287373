import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { JwtService } from "../services/auth/jwt.service";
import { isPlatformBrowser } from "@angular/common";


@Injectable({providedIn: 'root'})
export class AuthGuard {
  constructor(
    private router: Router,
    private jwtService: JwtService,
    @Inject(PLATFORM_ID) private platform: any,
  ) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (isPlatformBrowser(this.platform)) {

      const token = this.jwtService.getToken();
      if (token || (token.length > 3)) {
        return true;
      }

      this.router.navigateByUrl(`/auth/login?redirectTo=${state.url}`);
      return false;
    }
    return false;
  }
}
